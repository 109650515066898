<template>
    <div>
        <div id="header">
            <Header />
        </div>

        <div class="alpha">
            <div class="row0">
                <h3 align="center" style="font-size: 30pt;">Request Page</h3>
                <h5>Make a request</h5>
            </div>
            <div class="form" >
                <form id="service-request-form">
                    <fieldset>
                        <legend>Request For our Service</legend>
                    
                        <label for="full-name">First Name:</label>
                        <input type="text" id="full-name" required>

                        <label for="last-name">Last Name:</label>
                        <input type="text" id="last-name" required>

                        <label for="email">Email:</label>
                        <input type="email" id="email" required>

                        <label for="telephone-number">Telephone Number:</label>
                        <input type="tel" id="telephone-number" required>

                        <label for="date">Date:</label>
                        <input type="date" id="date" required>

                        <label for="venue">Venue:</label>
                        <input type="text" id="venue" required>

                        <label for="type-of-event">Type of Event:</label>
                        <select id="type-of-event" required>
                            <option value="Wedding">Wedding</option>
                            <option value="Birthday Party">Birthday Party</option>
                            <option value="Corporate Event">Corporate Event</option>
                            <option value="Birthday Party">House Warming</option>
                            <option value="Birthday Party">Naming Ceremony</option>
                            
                        </select>

                        <button type="submit" id="send-request-button">Send Request</button>
                    
                    </fieldset>
                </form>
            </div>
        </div>


        <div class="footer">
            <div class="foot-contain1">
                <div class="sub-footer1">
                    <div class="newsletter">
                        <h2>Ready to cook?</h2>
                        <br/>
                        <p>Sign up for our weekly newsletters!</p>
                    </div>
                    <div class="register">
                        <input type="text" id="email"  placeholder="Enter your email" required />
                        <button>SIGN UP </button>
                    </div>
                </div>

                <div class="sub-footer2">
                    <router-link to="/contact">Our Contact</router-link> |
                    &copy; Recipes. All Rights Reserved |
                    <router-link to="/about">About Website</router-link>
                </div>
            </div>

            <div class="foot-contain2">
                <div class="sub-footer3">
                    <ul>
                        <li><router-link to="/recipe">RECIPES</router-link></li>
                        <li>QUICK & EASY</li>
                        <li>IN THE KITCHEN</li>
                        <li>BUYING GUIDES</li>
                        <li>HOLIDAYS & SEASONS</li>
                    </ul>
                </div>

                <div class="sub-footer4">
                    <ul>
                        <li><router-link to="/about">About Us</router-link></li>
                        <li>Advertise</li>
                        <li>Terms of Service</li>
                        <li>Editorial Guidelines</li>
                        <li>Privacy Policy</li>
                        <li> <router-link to="/contact">Contact</router-link></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>


</template>


<script>
    import Header from './HeaderPage.vue'
   
    
    export default{
      components:{
        Header
        
      }
    }

    

    // const form = document.getElementById('service-request-form');
    // const button = document.getElementById('send-request-button');

    // button.addEventListener('click', (e) => {
    //     e.preventDefault(); // prevent form submission
    //     alert('Your Request has been sent!');
    // });

</script>


<style scoped>
        
        *{
            margin: 0;
            padding: 0;
            left: 0;
            font-family: Georgia, 'Times New Roman', Times, serif;
            /* box-sizing: border-box; */
        }

        .alpha{
            top: 150px;
            position: absolute;
            width: 100%;
        }

        .form{
            /* top: 150px;
            position: absolute; */
            height: 630px;
            width: 100%;
            left: 35%;
            /* background-color: aquamarine; */
            /* display: flex; */
            /* justify-content: center; */
            margin-top: 10px;
            padding-top: 10px;
        }

        fieldset{
            padding-top: 30px;
            padding-inline: 30px;
            padding-bottom: 20px;
            text-align: left;
            /* border: none; */
            /* background-image: linear-gradient(to bottom-right, red,blue,green); */
            /* background-color: darkgrey; */
        }

        form{
            width: 100%;
            max-width: 600px;
            /* background-color: khaki; */

        }

        #service-request-form {
            max-width: 500px;
            margin: 40px auto;
            background-color: #f9f9f9;
            padding: 20px;
            border: 1px solid #ccc;
            box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
        }

        label {
            display: block;
            margin-bottom: 10px;
        }

        input, select {
            width: 100%;
            padding: 10px;
            margin-bottom: 20px;
            border: 1px solid #ccc;
        }

        button[type="submit"] {
            background-color: khaki;
            color: #000000;
            padding: 10px 20px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            /* font-size: 45px; */
        }

        button[type="submit"]:hover {
            background-color: darkgoldenrod;
            color: white;
        }

        

    /* =========================footer css=================== */

       /* @media screen and (max-width: 600px) {
            div.foot-contain2 {
                display: none;
            }
        }

        @media screen and (max-width: 400px) {
            div.footer{
               width: 100%;
            }
        } */

        .footer{
            
            top:1050px;
            /* left:0px; */
            /* padding-left: 10px; */
            width:100%;
            height:300px;
            background-color: khaki;
            position:absolute;
            display: flex;
            border-top-left-radius: 50px;

        }

        .foot-contain1{
            flex: 1;
            /* background-color: azure; */
            margin-left: 20px;

        }

        .sub-footer1{
            /*border: 2px #c90303 solid;*/
            padding: 10px;
            display: flex;
            flex-direction: column;
            /* align-items: start; */
            /* justify-content:center; */
            
        }

        .sub-footer1 h2{
            /* text-align: left; */
            /* margin-bottom: -20px; */

            box-sizing: border-box;
            color: rgb(34, 34, 34);
            display: block;
            font-family: "Frank Ruhl Libre", Georgia, serif;
            font-size: 26px;
            font-weight: 400;
            height: 33.92px;
            margin-bottom: 4.8px;
            margin-top:16px;
            order: 1;
            text-align: left;
            width :344.95px;
            -webkit-font-smoothing: antialiased;
        }
        
        .sub-footer1 p{
            text-align: left;
        }

        .sub-footer1 input{
            width:35%;
            align-content: flex-start;
            /* height: 20px; */
            padding: 10px;
            outline: 0;
            border: 1px solid black;
            color: black;
            background: transparent;
            font-size: 15px;
            
        }

        .sub-footer1 button{        
            width: 15%;
            margin-left: 8px;
            background-color: rgb(222, 162, 51);
            border: none;
            height: 40px;
            border-radius: 5px;
            color: white;
            box-sizing: border-box;
            cursor: pointer;
            font-family: Roboto, Helvetica, sans-serif;
            font-size: 16px;
            font-weight: 700;
            font-stretch: 100%;
        }

        .sub-footer1 button:hover{ 
            background-color:  rgb(164, 131, 70);;
        }

        .register{
            /* background-color: aqua; */
            text-align: left;

        }

        .sub-footer2{
            /*border: 2px black;*/
            padding: 30px;
            padding-left: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: left;
            /* background-color: antiquewhite; */
            
        }

        .foot-contain2{
            flex: 1;
            /* background-color: darkolivegreen; */
            display: flex;
        }

        .sub-footer3{
            /*border: 2px #c90303 solid;*/
            padding: 10px;
            display: flex;
            /* flex-direction: column; */
            align-items: center;
            justify-content:left;
            flex: 1;
        }

        .sub-footer3 li{
            list-style: none;
            text-align: left;
            padding-top:10px;
        }


        .sub-footer4{
            /*border: 2px #c90303 solid;*/
            padding: 10px;
            display: flex;
            /* flex-direction: column; */
            align-items: center;
            justify-content:left;
            flex: 1;
        }

        .sub-footer4 li{
            list-style: none;
            text-align: left;
            padding-top:10px;
        }

        .footer a{
            color: rgb(44, 62, 80);
            text-decoration:none;
            /* padding: 20px;
            padding-left: 0; 
            background-color: khaki; */
            
        }
</style>