
import { createWebHistory,createRouter } from "vue-router";


import Home from './components/HomePage.vue'
import Recipe from './components/RecipePage.vue'
import About from './components/AboutPage.vue'
import Contact from './components/ContactPage.vue'
import Request from './components/RequestPage.vue'

// ==============detail pages==============
import White from './components/DetailWhite.vue'
import Bitter from './components/DetailBitter.vue'
import Groundnut from './components/DetailGroundnut.vue'
import Coconut from './components/DetailCoconut.vue'
import Fishr from './components/DetailFishr.vue'
import Ofada from './components/DetailOfada.vue'
import Friedr from './components/DetailFriedr.vue'
import Egusi from './components/DetailEgusi.vue'
import Jollofs from './components/DetailJollofs.vue'
import Edika from './components/DetailEdika.vue'
import Porridge from './components/DetailPorridge.vue'
import Jollofr from './components/DetailJollofr.vue'
import Ogbono from './components/DetailOgbono.vue'
import Fishs from './components/DetailFishs.vue'
import Chickenf from './components/DetailChickenf.vue'



const routes = [
    { Name:'Home', path: '/', component: Home },
    { Name:'Recipe', path: '/recipe', component: Recipe },
    { Name:'About', path: '/about', component: About },
    { Name:'Contact', path: '/contact', component: Contact },
    { Name:'Request', path: '/request', component: Request },
    // =================detail pages=================
    { Name:'White', path: '/white', component: White },
    { Name:'Bitter', path: '/bitter', component: Bitter },
    { Name:'Groundnut', path: '/groundnut', component: Groundnut },
    { Name:'Coconut', path: '/coconut', component: Coconut },
    { Name:'Fishr', path: '/fishr', component: Fishr },
    { Name:'Ofada', path: '/ofada', component: Ofada },
    { Name:'Friedr', path: '/friedr', component: Friedr },
    { Name:'Egusi', path: '/egusi', component: Egusi },
    { Name:'Jollofs', path: '/jollofs', component: Jollofs },
    { Name:'Edika', path: '/edika', component: Edika },
    { Name:'Porridge', path: '/porridge', component: Porridge },
    { Name:'Jollofr', path: '/jollofr', component: Jollofr },
    { Name:'Ogbono', path: '/ogbono', component: Ogbono },
    { Name:'Fishs', path: '/fishs', component: Fishs },
    { Name:'Chickenf', path: '/chickenf', component: Chickenf },

];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
