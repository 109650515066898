<template>
    <div>
      <div id="header">
        <Header />
      </div>
      <div id="content1">
        <Content />
      </div>
      <div id="footer">
        <Footer />
      </div>


    </div>
</template>

<script>
import Header from './HeaderPage.vue'
import Content from './ContentPage.vue'
import Footer from './FooterPage.vue'
export default{
  components:{
    Header,
    Content,
    Footer
  }
}
</script>

<style scoped>


</style>