<template>
    <div class="container">
       
       
            <div class="high">
                <div class="left-high">

                </div>
                <div class="right-high">
                    <p>Welcome to Flo-Recipes,Looking to master a basic cooking skill or learn more about an ingredient? You've come to the right place. Pull up a chair and we'll walk you through everything we know!</p>
                </div>
            </div>
            <div class="row1">
                <table  class="table" width="100%" height="950px" border="0">
                    <!-- first row -->
                    <tr>
                    <td width="33.33">
                        <table width="100%" border="0">
                            <tr>
                                <th>
                                    White Soup
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    <router-link to="/white"><img src="../assets/whitesoup.jpeg" /></router-link>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <router-link to="/white"><button>Get Started>>></button></router-link>
                                    
                                </td>
                            </tr>
                        </table>
                        
                    </td>
                    <td width="33.33">
                        <table width="100%" border="0">
                            <tr>
                                <th>
                                    Bitterleaf Soup
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    <img src="../assets/bitterleaf.jpeg" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <router-link to="/bitter"><button>Get Started>>></button></router-link>
                                </td>
                            </tr>
                        </table>
                        
                    </td>
                    <td width="33.33">
                        <table width="100%" border="0">
                            <tr>
                                <th>
                                    Fried Rice
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    <img src="../assets/friedrice.jpg" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <router-link to="/friedr"><button>Get Started>>></button></router-link>
                                </td>
                            </tr>
                        </table>
                        
                    </td>
                    </tr>

                    <!-- second row -->
                    <tr>
                    <td width="33.33">
                        <table width="100%" border="0">
                            <tr>
                                <th>
                                    Ogbono-Okro soup
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    <img src="../assets/ogbonookro.jpeg" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <router-link to="/ogbono"><button>Get Started>>></button></router-link>
                                </td>
                            </tr>
                        </table>
                        
                    </td>
                    <td width="33.33">
                        <table width="100%" border="0">
                            <tr>
                                <th>
                                    Fish Stew
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    <img src="../assets/fishstew.jpeg" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <router-link to="/fishs"><button>Get Started>>></button></router-link>
                                </td>
                            </tr>
                        </table>
                        
                    </td>
                    <td width="33.33">
                        <table width="100%" border="0">
                            <tr>
                                <th>
                                    Ofada Sauce
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    <img src="../assets/ofadasauce.jpeg" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <router-link to="/ofada"><button>Get Started>>></button></router-link>
                                </td>
                            </tr>
                        </table>
                        
                    </td>
                    </tr>

                    <!-- third row -->
                    <tr>
                    <td width="33.33">
                        <table width="100%" border="0">
                            <tr>
                                <th>
                                    Yam Porridge
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    <img src="../assets/yamporridge.jpeg" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <router-link to="/porridge"><button>Get Started>>></button></router-link>
                                </td>
                            </tr>
                        </table>
                        
                    </td>
                    <td width="33.33">
                        <table width="100%" border="0">
                            <tr>
                                <th>
                                    Jollof Spaghetti
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    <img src="../assets/jollofspag.jpeg" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <router-link to="/jollofs"><button>Get Started>>></button></router-link>
                                </td>
                            </tr>
                        </table>
                        
                    </td>
                    <td width="33.33">
                        <table width="100%" border="0">
                            <tr>
                                <th>
                                    Fried Chicken
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    <img src="../assets/friedchicken.jpeg" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <router-link to="/chickenf"><button>Get Started>>></button></router-link>
                                </td>
                            </tr>
                        </table>
                        
                    </td>
                    </tr>
                </table>
            </div>
        <!-- <div class="row2"></div>
        <div class="row3"></div> -->


    </div>
</template>

<script>
export default{
  
}
</script>

<style scoped>
    *{
        margin: 0;
        padding: 0;
        font-family: Georgia, 'Times New Roman', Times, serif;
        box-sizing: border-box;
    }

    .container{
        top: 120px;
        left: 0;
        width: 100%;
        height:1550px;
        /* background-color: aqua; */
        position: absolute;
    }

   
    
    
    .table{
        margin-top: 20px;
    }

    .high{
        height: 600px;
        font-size: 30px;
        background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(../assets/title.jpg);
        background-size: cover;
        background-position: center;
        padding-top: 20px;
        display: flex;
    }

    .left-high{
        flex: 1;
    }

    .right-high{
        flex: 1;
        padding-top: 40px;
        text-align: left;
        color: #ffffff;
    }


    .table button {
        background-color: khaki;
        border-radius: 10px;
        border: none;
        color: black;
        padding: 10px 15px;
        font-size: 15px;
        cursor: pointer;
        background-image: none;
        /* margin: 5px; */
    }

    .table button:hover{
        background-color: darkgoldenrod;
        color: #fff;
    }

    th{
        font-size: 25px;
        font-weight: bold;
    }

    table img{
        height: 250px;
        width: 250px;
    }



</style>