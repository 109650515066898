<template>
    <div>
        <div id="header">
            <Header />
        </div>


        <div class="top">
            <h2>Contact Us</h2>
            <br/>
            <h3>We Welcome all feedback</h3>
            <br/>
            <p>If you have any feedback, positive or negative, about this website, let us know below.</p>
            <br/>
        </div>
    
        <div class="alpha">
           
            <div class="row1">
                <form>
                    <div class="row">
                        <div class="input-group">
                              <input type="text" id="name" required>
                            <label for="name"><i class="fa-solid fa-user"></i>  Your Name</label>
                        </div>
                        <div class="input-group">
                            <input type="text" id="number" required>
                            <label for="number"><i class="fas fa-phone-square-alt"></i> Phone No.</label>
                        </div>
                    </div>
       
                    <div class="input-group">
                        <input type="text" id="email" required>
                        <label for="email"><i class="fas fa-envelope"></i> Email Id</label>
                      </div>
                    <div class="input-group">
                        <textarea id="message"  rows="8" required></textarea>
                        <label for="message"><i class="fas fa-comment"></i> Your Message</label>
                    </div>
                    <button type="submit">SUBMIT <i class="fas fa-paper-plane"></i></button>
                </form>
        
            </div>

            <div class="alpha2">
                <div class="container1">
                    <div class="box1">
                        <h3> Our Address </h3><br/><br/>
                        <h4>112, Lagos Road.<br/> Haurna Bus Stop <br/>Ikorodu. Lagos </h4>
                    </div>

                    <div class="box2">
                        <h3> Phone Number </h3><br/><br/><br/>
                        <h4> 09056028526 </h4>
                    </div>

                    <div class="box3">
                        <h3> E-mail Addresss </h3><br/><br/><br/>
                        <h4> babat4lyf@gmail.com </h4>
                    </div>

                    <div class="box4">
                        <h3> Socials:</h3><br/><br/>
						<i class="fa-brands fa-instagram fa-2xl"></i>
						<i class="fa-brands fa-whatsapp fa-2xl"></i>
						<i class="fa-brands fa-facebook fa-2xl"></i>
						<i class="fa-brands fa-twitter fa-2xl"></i>
                    </div>
                </div>
            </div>

           
        </div>

        <div class="footer">
            <div class="foot-contain1">
                <div class="sub-footer1">
                    <div class="newsletter">
                        <h2>Ready to cook?</h2>
                        <br/>
                        <p>Sign up for our weekly newsletters!</p>
                    </div>
                    <div class="register">
                        <input type="text" id="email"  placeholder="Enter your email" required />
                        <button>SIGN UP </button>
                    </div>
                </div>

                <div class="sub-footer2">
                    <router-link to="/contact">Our Contact</router-link> |
                    &copy; Recipes. All Rights Reserved |
                    <router-link to="/about">About Website</router-link>
                </div>
            </div>

            <div class="foot-contain2">
                <div class="sub-footer3">
                    <ul>
                        <li><router-link to="/recipe">RECIPES</router-link></li>
                        <li>QUICK & EASY</li>
                        <li>IN THE KITCHEN</li>
                        <li>BUYING GUIDES</li>
                        <li>HOLIDAYS & SEASONS</li>
                    </ul>
                </div>

                <div class="sub-footer4">
                    <ul>
                        <li><router-link to="/about">About Us</router-link></li>
                        <li>Advertise</li>
                        <li>Terms of Service</li>
                        <li>Editorial Guidelines</li>
                        <li>Privacy Policy</li>
                        <li> <router-link to="/contact">Contact</router-link></li>
                    </ul>
                </div>
            </div>
        </div>

        <!-- <div id="footer">
            <Footer />
        </div>
     -->
    </div>
    
    </template>
    
    
    <script>
    import Header from './HeaderPage.vue'
    // import Content from './ContentPage.vue'
    // import Footer from './FooterPage.vue'
    // import Body from './BodyPage.vue'
    
    export default{
      components:{
        Header,
        // Content,
        // Footer
        // Body
      }
    }
    </script>
    
    
    <style scoped>

        .top{
            top: 130px;
            position: absolute;
            width: 100%;
        }

        .top h2{
            font-size: 30pt
        }

        .top h3, p{
            text-align: left;
            
        }

        .alpha{
            top: 260px;
            position: absolute;
            width: 100%;
            
        }

        .row1{
            width: 100%;
            height: 550px;
            /*background-image: /*linear-gradient(rgba(0,0,0,0,7), #3551b5), url(../background.jpg);*/
            /* background-position: center; */
            /* background-size: cover; */
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: khaki;
            border-bottom-right-radius: 80px;
        
        }

        



        .container1{
            display: flex;
            background-color: white;
        }
                
        .container1 div{
            /*border: 2px #ccc solid;*/
            padding: 10px;
        }

        .container1 img{
            height: 30px;
            width: 50px;
        
        }



        .box1, .box2, .box3{
            flex:1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .box4{
            flex:1.2;
            display: flex;
            align-items: center;
            justify-content: space-evenly;

        }

        /* =====================================================form css==================================================== */

        *{
            margin: 0;
            padding: 0;
            left: 0;
            box-sizing: border-box;
        }



        form{
            width: 90%;
            max-width: 600px;
            background-color: khaki;

        }

        .input-group{
            margin-bottom: 30px;
            position: relative;
        }

        input, textarea{
            width: 100%;
            padding: 10px;
            outline: 0;
            border: 1px solid black;
            color: black;
            background: transparent;
            font-size: 15px;
        }

        label{
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            padding: 10px;
            color: #000;
            cursor: text;
            transition: 0.2s;
        }



        input:focus~label,
        input:valid~label,
        textarea:focus~label,
        textarea:valid~label{
            top: -35px;
            font-size: 14px;

        }

        .row{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .row .input-group{
            flex-basis: 48%;
        }

        button{
            padding: 10px 0;
            color: #000;
            outline: none;
            background: transparent;
            border: 1px solid #000;
            width: 100%;
            cursor: pointer;
            border-radius: 40px;
            transition: 0.2s all;
        
        }

        button:active{
            transform: scale(0.98);

        }

        button:hover{
            background-color: lightgoldenrodyellow ;
        }

        /* =========================footer css=================== */
        /* .footer{
            top:950px;
            left:0px;
            width:100%;
            height:200px;
            background-color: khaki;
            position:absolute;
            border-top-left-radius: 80px;
        } */

        .footer{
        
            top:960px;
            left:0px;
            /* padding-left: 10px; */
            width:100%;
            height:300px;
            background-color: khaki;
            position:absolute;
            border-top-left-radius: 80px;
            display: flex;

        }

        .foot-contain1{
            flex: 1;
            /* background-color: azure; */
            margin-left: 20px;

        }

        .sub-footer1{
            /*border: 2px #c90303 solid;*/
            padding: 10px;
            display: flex;
            flex-direction: column;
            /* align-items: start; */
            /* justify-content:center; */
            
        }

        .sub-footer1 h2{
            /* text-align: left; */
            /* margin-bottom: -20px; */

            box-sizing: border-box;
            color: rgb(34, 34, 34);
            display: block;
            font-family: "Frank Ruhl Libre", Georgia, serif;
            font-size: 26px;
            font-weight: 400;
            height: 33.92px;
            margin-bottom: 4.8px;
            margin-top:16px;
            order: 1;
            text-align: left;
            width :344.95px;
            -webkit-font-smoothing: antialiased;
        }
        
        .sub-footer1 p{
            text-align: left;
        }

        .sub-footer1 input{
            width:35%;
            align-content: flex-start;
            /* height: 20px; */
            padding: 10px;
            outline: 0;
            border: 1px solid black;
            color: black;
            background: transparent;
            font-size: 15px;
            /* box-sizing: n; */
            
            
        }

        .sub-footer1 button{        
            width: 15%;
            margin-left: 8px;
            background-color: rgb(222, 162, 51);
            border: none;
            height: 40px;
            border-radius: 5px;
            color: white;
            box-sizing: border-box;
            cursor: pointer;
            font-family: Roboto, Helvetica, sans-serif;
            font-size: 16px;
            font-weight: 700;
            font-stretch: 100%;
        }

        .sub-footer1 button:hover{ 
            background-color:  rgb(164, 131, 70);;
        }

        .register{
            /* background-color: aqua; */
            text-align: left;

        }

        .sub-footer2{
            /*border: 2px black;*/
            padding: 30px;
            padding-left: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: left;
            /* background-color: antiquewhite; */
            
        }

        .foot-contain2{
            flex: 1;
            /* background-color: darkolivegreen; */
            display: flex;
        }

        .sub-footer3{
            /*border: 2px #c90303 solid;*/
            padding: 10px;
            display: flex;
            /* flex-direction: column; */
            align-items: center;
            justify-content:left;
            flex: 1;
        }

        .sub-footer3 li{
            list-style: none;
            text-align: left;
            padding-top:10px;
        }


        .sub-footer4{
            /*border: 2px #c90303 solid;*/
            padding: 10px;
            display: flex;
            /* flex-direction: column; */
            align-items: center;
            justify-content:left;
            flex: 1;
        }

        .sub-footer4 li{
            list-style: none;
            text-align: left;
            padding-top:10px;
        }

        .footer a{
            color: rgb(44, 62, 80);
            text-decoration:none;
            /* padding: 20px;
            padding-left: 0; 
            background-color: khaki; */
            
        }
    </style>