<template>
    <div>
        <div class="top-div">
            <h4>Gmail:babat4lyf@gmail.com</h4>
            <h4>Phone:09056028526</h4>
        </div>
        <div class="flex-div">
            <div class="nav-left">
               
                <img alt="Vue logo" src="./../assets/bitterleaf.jpeg">
                <h3><span style="color: darkkhaki;">Flo</span> <span style="color:orange;">Recipes</span></h3>  
                
            </div>

           <div class="nav-middle">
              <router-link to="/">Home</router-link>
              <router-link to="/recipe">Recipes</router-link>
              <router-link to="/about">About</router-link>
              <router-link to="/contact">Contact</router-link>

                <!-- <h3>Home | Recipes| About Us | Contact Us </h3> -->
            </div>



            <div class="nav-right">
               <!--  <a href="login.html"><button>Login</button></a>
                <a href="register.html"> <button>Register</button></a> -->

                <button>Login</button>
                <button>Register</button>
                <!-- <img src="./../assets/user.png" class="user-icon"/> -->
               
            </div>
        </div>
        <router-view />
    </div>
</template>

<script>
export default{
  
}
</script>

<style scoped>
    *{
        margin: 0;
        padding: 0;
        font-family: Georgia, 'Times New Roman', Times, serif;
        box-sizing: border-box;
    }

    .nav-right, .nav-middle, .nav-left{
        display: flex;
        align-items: center;
       
    }
    
    .top-div{
        top: 0;
        left: 0;
        position: fixed;
        z-index: 1;
        background-color: khaki;
        height: 30px;
        width: 100%;
        /* text-align: left; */
        display: flex;
        
        /* justify-content: space-around; */
        align-items: start;
    }

    .top-div h4{
        padding-right: 15px;
        padding-left: 5px;
    }

    .flex-div{
        padding: 10px 2%;
        justify-content: space-between;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        background: #fff;
        position: fixed;
        top: 30px;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        
    }

    .flex-div button {
        background-color: khaki;
        border-radius: 10px;
        border: none;
        color: black;
        padding: 10px 15px;
        font-size: 15px;
        cursor: pointer;
        background-image: none;
        margin: 5px;
    }

    .flex-div button:hover{
        background-color: darkgoldenrod;
        color: #fff;
    }


    .flex-div a{
        color:#5a5a5a;
        padding: 20px;
        /*padding-left: 0;*/
        text-decoration:none;  
        /*background-color: khaki; */ 
    }

    .flex-div a:hover{
        background-color: darkgoldenrod;
        border-radius: 40px;
        color: #fff;
    }

    .nav-right a{
        padding: 5px;
        
    }

    .nav-right a:hover{
        background-color: white;
    }

    img{
        width: 50px;
        /*height: 50px;*/
        cursor: pointer;
    }

    .nav-right img{
        width: 25px;
        height: 25px;
        margin-right: 25px;

    }

    .nav-right .user-icon{

        margin-right: 0;
    }


    

    .nav-left .logo{
        width: 50px;
    }
</style>